/**
 * @description 地址
*/
export default $axios => ({
    // 获取地址校验规则
    getRules(params) {
        return $axios.get(`/mall/v1/address/queryAddAddressCondition`, {
            params
        });
    },
    // 获取地址列表
    getAddressList(params) {
        return $axios.get(`/mall/v1/user/listUserAddress`, {
            params,
            config: {
                hasLoading: true
            }
        });
    },
    // zipCode反查地区
    getPostCodeAddress(params) {
        return $axios.get(`/mall/v1/city/v1/city/listByPostcode`, {
            params
        });
    },
    // 新增地址
    setAdd(params) {
        return $axios.post(`/mall/v1/user/addUserAddress`, {
            ...params
        }, {
            config: {
                hasLoading: true,
            }
        });
    },
    // 编辑地址
    setEdit(params) {
        return $axios.post(`/mall/v1/user/updateUserAddress`, {
            ...params
        }, {
            config: {
                hasLoading: true,
            }
        });
    },
    // 删除地址
    deleteAddress(params) {
        return $axios.post(`/mall/v1/user/delUserAddress`, {
            ...params
        }, {
            config: {
                hasLoading: true,
            }
        });
    },
    // 修改账单地址
    setEditBill(params, hasLoading = true) {
        return $axios.post(`/mall/v1/module/order/v1/update/billingAddress`, {
            ...params
        }, {
            config: {
                hasLoading
            }
        });
    }
});