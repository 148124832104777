/**
 * @description 搜索
*/
export default $axios => ({
    // 热搜词/底纹词
    getSearchHot(params) {
        return $axios.get(`/mall/v1/words/searchWordsList`, {
            params: {
                ...params
            }
        });
    },
    // 获取搜索词条
    getSearchEntry(params) {
        return $axios.get(`/mall/v1/words/searchWordsLibrary`, {
            params: {
                ...params
            }
        });
    },
    // 获取搜索结果
    getSearchList(params, hasLoading = false) {
        return $axios.get(`/mall/v3/spu/search`, {
            params: {
                ...params
            },
            config: {
                hasLoading
            }
        })
    },
    // 搜索日志
    setSearchLog(params) {
        return $axios.post(`/mall/v1/spu/takeSearchLog`, {
            ...params
        });
    }
});