/**
 * @description 地址
*/
export default $axios => ({
    // 获取地址校验规则
    getHorse(params) {
        return $axios.get(`/mall/v1/user/affiliate`, {
            params
        });
    },
    // affiliate 信息保存
    setAffiliateSave(params) {
        return $axios.post(`/mall/v1/user/createAffiliate`, {
            ...params
        });
    },
    // dropShipping 信息保存
    setDropShippingSave(params) {
        return $axios.post(`/mall/v1/user/createDropShipping`, {
            ...params
        });
    },
    // 获取 Affiliate 详情信息
    getAffiliateInfo(params) {
        return $axios.get(`/mall/v1/user/detailAffiliate`, {
            params
        });
    },
    // 获取 Drop Shipping 详情信息
    getDropShippingInfo(params) {
        return $axios.get(`/mall/v1/user/detailDropShipping`, {
            params
        });
    },
});