export default $axios => ({
    // 获取分组列表
    getSitemapList(params) {
        return $axios.get(`/mall/v1/sitemap/listSiteMap`, {
            params,
        });
    },
    // 分组列表搜索
    getSitemapSearch(params) {
        return $axios.get(`/mall/v1/sitemap/sitemapSearch`, {
            params,
            config: {
                hasLoading: true
            }
        });
    },
    // 获取平级分组
    getSitemapPeer(params) {
        return $axios.get(`/mall/v1/sitemap/listSiteMapPeers`, {
            params,
            config: {
                hasLoading: true
            }
        });
    },
});