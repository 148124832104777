/**
 * @description 分组
*/
export default $axios => ({
    // 获取分类列表
    getCategory() {
        return $axios.get(`/mall/v2/spu/listCategory`);
    },
    // 获取分组展示类别
    getCategoryStyle() {
        return $axios.get(`/mall/v2/spu/getCategoryStyle`);
    },
    // 获取分组列表
    getCategoryList(params) {
        return $axios.get(`/mall/v3/spu/categorySearch`, {
            params: {
                ...params
            }
        })
    },
    // 获取分类所属分组
    getCategoryGroup(params) {
        return $axios.get(`/mall/v1/spu/searchPeers`, {
            params: {
                ...params
            }
        })
    },
    // 获取树形图结构列表
    getMenu() {
        return $axios.get(`/mall/v1/menu/listMenu`);
    },
    // 获取分类所属分组
    getCategorySearch(params) {
        return $axios.get(`/mall/v1/spu/categorySearch`, {
            params
        })
    },
});