export default $axios => ({
    // 获取帮助列表
    getHelpList() {
        return $axios.get(`/mall/v1/help-center`);
    },
    // 获取帮助搜索结果
    getHelpSearch(params) {
        return $axios.get(`/mall/v1/help-center/problem`, {
            params,
            config: {
                hasLoading: true
            }
        });
    },
    // 获取问答回复
    getHelpDetail(params, config) {
        return $axios.get(`/mall/v1/help-center/details`, {
            params,
            headers: config,
            config: {
                hasLoading: true
            }
        });
    },
    // 获取问答类型列表
    getHelpType(params, config) {
        return $axios.get(`/mall/v1/user/v1/feedback/getType`, {
            params,
            headers: config,
            config: {
                hasLoading: true
            }
        });
    },
    // 获取协议模板
    getPolicy(params, config = {}) {
        return $axios.get(`/mall/v1/rich/text/getContentInfo`, {
            params,
            headers: config, 
            config: {
                hasLoading: true
            }
        });
    },
    // 订阅接口
    setSubscribe(params) {
        return $axios.post(`/mall/v1/module/user/v1/subscribe/addSubscribe`, {
            ...params
        }, {
            config: {
                hasLoading: true,
            }
        })
    },
    // 取消订阅接口
    setUnSubscribe(params) {
        return $axios.post(`/mall/v1/module/user/v1/subscribe/cancelSubscribe`, {
            ...params
        }, {
            config: {
                hasLoading: true,
            }
        })
    },
    // 用户反馈问题状态是否有帮助
    setHelpStatus(params, config) {
        return $axios.post(`/mall/v1/problem/v1/add/feedback`, {
            ...params
        }, {
            headers: config,
            config: {
                hasLoading: true,
            }
        })
    },
    // 反馈接口
    setFeedBack(params, config) {
        return $axios.post(`/mall/v1/user/feedback`, {
            ...params
        }, {
            headers: config,
            config: {
                hasLoading: true,
            }
        })
    },
});