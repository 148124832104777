export default $axios => ({
    // 获取语言列表
    getLang() {
        return $axios.get(`/mall/v1/language/getAllLanguage`)
    },
    // 获取货币列表
    getCurrency() {
        return $axios.get(`/mall/v1/module/currency/v1/currency/list`)
    },
    // 获取国家ip
    getCountryIp(params, headers = {}) {
        return $axios.get(`/mall/v1/country/v1/get/countryInfo`, {
            params,
            headers
        })
    },
    // 获取国家列表
    getCountryList() {
        return $axios.get(`/mall/v1/address/queryCountry`)
    },
    // 获取州/省
    getState(params) {
        return $axios.get(`/mall/v1/province/v1/province/listByCountryCode`, {
            params
        })
    },
    // 获取州/市
    getCity(params) {
        return $axios.get(`/mall/v1/city/v1/city/listByStateOrProvince`, {
            params
        })
    },
    // 获取当前国家运输信息
    getShipping(params, config) {
        return $axios.get(`/mall/v1/shipping/queryShippingMethod`, {
            params,
            headers: config
        })
    },
    // 获取国家支付方式图标
    getCountryPayIcon(params) {
        return $axios.get(`/mall/v1/config/v1/foot/get/byCountryCode`, {
            params
        })
    },
    // 获取阿里云oss
    getAliOss() {
        return $axios.get(`/mall/v1/aliyun/oss/getOssSts`)
    },
    // 获取广告位
    getAd(params) {
        /*
            places -> 投放位置 1-启动首页图，2-首页弹窗图，3-商品详情页，4-分组页广告，5-支付结果页广告，6-订单页广告，7-购物车广告 10 搜索页 12 顶通banner 13 订单列表 14 评价列表 15 个人中心
            16 商品详情广告
        */
        return $axios.get(`/mall/v2/ats/searchAts`, {
            params: {
                ...params
            }
        })
    },
    // 货币金额转换
    setCcyCnv(params) {
        // params->amounts 转换金额，多个逗号隔开 ','
        return $axios.post(`/mall/v1/module/currency/v1/calculation/amount`, {
            ...params
        });
    },
    // 文本翻译
    setTranslateText(params) {
        // sourceLang 原语言-评论语言 targetLang 需要翻译的语言 content 翻译内容 []
        return $axios.post(`/mall/v1/translate/batchTranslate`, {
            ...params
        });
    },
    // 获取商品浏览历史
    getProductHistory(params) {
        return $axios.get(`/ext/mallstock/recommend/v1/product/spu/recently_view`, {
            params
        })
    },
    // 推荐
    getRecCommon(params) { // 公共推荐
        return $axios.get(`/mall/v5/rmd/rmdSpu`, {
            params,
            config: {
                hasLoading: true
            }
        })
    },
    getNewRecCommon(params, hasLoading = false) { // 公共推荐
        return $axios.get(`/mall/v3/rmd/rmdSpu`, { // 商品推荐 index -> 1 首页 2 商品详情 3 购物车页 4 个人中心 5 搜索页 6 支付成功页 7 订单页 8 评论页
            params,
            config: {
                hasLoading
            }
        })
    },
    getRecV4Common(params, hasLoading = false) { // 公共推荐
        return $axios.get(`/mall/v4/rmd/rmdSpu`, { // 商品推荐 index -> 1 首页 2 商品详情 3 购物车页 4 个人中心 5 列表页
            params,
            config: {
                hasLoading
            }
        })
    },
    getRecV5Common(params, hasLoading = false) { // 公共推荐
        return $axios.get(`/mall/v5/rmd/rmdSpu`, { // 商品推荐 index -> 1 首页 2 商品详情 3 购物车页 4 个人中心 5 列表页
            params,
            config: {
                hasLoading
            }
        })
    },
    getRecProduct(params) { // 商品详情推荐
        return $axios.get(`/ext/mallstock/recommend/v1/product/spu/detail_recommand`, {
            params,
            config: {
                hasLoading: true
            }
        })
    },
    getRecBag(params) { // 购物车推荐
        return $axios.get(`/ext/mallstock/recommend/v1/product/spu/add_recommand`, {
            params,
            config: {
                hasLoading: true
            }
        })
    },
    getRecMine(params) { // 个人中心推荐
        return $axios.get(`/ext/mallstock/recommend/v1/product/spu/mine_recommand`, {
            params,
            config: {
                hasLoading: true
            }
        })
    },
    getRecSearch(params) { // 搜索推荐
        return $axios.get(`/ext/mallstock/recommend/v1/product/spu/search_recommand`, {
            params,
            config: {
                hasLoading: true
            }
        })
    },
    getRecReview(params) { // 评价成功推荐
        return $axios.get(`/ext/mallstock/recommend/v1/product/spu/review_recommand`, {
            params,
            config: {
                hasLoading: true
            }
        })
    },
    getRecOrder(params) { // 订单列表推荐
        return $axios.get(`/ext/mallstock/recommend/v1/product/spu/order_recommand`, {
            params,
            config: {
                hasLoading: true
            }
        })
    },
    getRecCard(params) { // 贺卡推荐列表
        return $axios.get(`/mall/v1/module/accessories/v1/recommend/afterAddCart`, {
            params,
            config: {
                hasLoading: true
            }
        })
    }
  });