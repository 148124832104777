/**
 * @description 商品详情
*/
export default $axios => ({
    // 获取商品详情
    getDetail(params, hasLoading = true) {
        // return $axios.get(`/mall/v2/spu/getSpuById`, {
        return $axios.get(`/mall/v3/spu/getSpuById`, {
            params,
            config: {
                hasLoading
            }
        });
    },
    // 获取详情资源位
    getResource(params) {
        return $axios.get(`/mall/v1/detailsPics/findPicsture`, {
            params,
        });
    },
    // 获取商品id查询商品
    getSearchProduct(params) {
        return $axios.get(`/mall/v3/spu/deviceHistory`, {
            params,
            config: {
                hasLoading: true
            }
        });
    },
    // 获取商品id查询推荐词
    getRecSearchWord(params) {
        return $axios.get(`/mall/v1/spu/searchSmartKeyword`, {
            params
        });
    },
    // 获取定制化规则
    getCustomize(params) {
        return $axios.get(`/mall/v1/yfn/custom/findCustomize`, {
            params
        })
    },
    // 获取收藏状态
    getWishStatus(params) {
        return $axios.get(`/mall/v1/collect/findCollect`, {
            params
        })
    },
    // 获取贺卡商品
    getCardProduct(params) {
        // scene -> detail 详情 cart 购物车、spuId
        return $axios.get(`/mall/v1/module/accessories/queryPageGreetCardInfo`, {
            params
        })
    },
    // 获取商品分类
    getCategory(params) {
        return $axios.get(`/mall/v1/spu/findSpuItemize`, {
            params
        })
    },
    // 获取分类商品列表
    getCategoryList(params) {
        return $axios.get(`/mall/v1/spu/queryItemizeProduct`, {
            params
        })
    },
    // 设置收藏状态
    setWishStatus(params) {
        return $axios.post(`/mall/v1/collect/addOrDelCollect`, {
            ...params
        }, {
            config: {
                hasLoading: true
            }
        });
    },
    // 批量收藏
    setAddWish(params) {
        return $axios.post('/mall/v1/cart/moveToCollect', params, {
            headers: {
                "Content-Type": "application/json",
            },
            config: {
                hasLoading: true,
                dataType: 2
            },
        })
    },
    // 捆绑价格重新计算接口
    setBundleCalc(params) {
        return $axios.post('/mall/v1/bind/calcBindGoodsPrice', params, {
            headers: {
                "Content-Type": "application/json",
            },
            config: {
                hasLoading: true,
                dataType: 2
            },
        })
    },
    // 加购
    setAddBag(params, hasLoading) {
        return $axios.post(`/mall/v2/cart/addCart`, {
            ...params
        }, {
            config: {
                hasLoading
            }
        });
    },
    // 加购结算埋点日志
    setAddBagLog(params) {
        return $axios.post(`/mall/v1/trace/cartAndSettleTrace`, {
            ...params
        }, {
            config: {
                dataType: 2
            }
        });
    }
});