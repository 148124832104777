/**
 * @description 商品详情
*/
export default $axios => ({
    // 获取用户信息
    getUseInfo(params, config) {
        return $axios.get(`/mall/v1/user/getById`, {
            params,
            headers: config || {}
        });
    },
    getUseInfoNoLogin(params, config) {
        return $axios.get(`/mall/v1/user/v1/info/getById`, {
            params,
            headers: config || {}
        });
    },
    // 获取用户地址列表
    getAddressList(params) {
        return $axios.get(`/mall/v1/user/listUserAddress`, {
            params
        });
    },
    // 获取收藏数量
    getCollectNum() {
        return $axios.get(`/mall/v1/collect/getCountNum`);
    },
    // 获取收藏列表
    getCollectList() {
        return $axios.get(`/mall/v1/collect/selectCollect`);
    },
    // 获取信用卡列表
    getCardList() {
        return $axios.get(`/mall/v1/user/listCreditCard`);
    },
    // 获取信用卡列表
    getRegisterStatus() {
        return $axios.get(`/mall/v1/user/getUserInfoByDeviceId`);
    },
    // 删除信用卡
    deleteCard(params) {
        return $axios.post(`/mall/v1/user/delCreditCard`, {
            ...params
        }, {
            config: {
                hasLoading: true
            }
        });
    },
    // 用户个人中心订单统计接口
    getCountOrder() {
        return $axios.get(`/mall/v1/module/user/v2/order/countUserOrder`);
    },
    // 删除收藏
    setDeleteCollect(params) {
        return $axios.post(`/mall/v1/collect/delBatchCollect`, {
            ...params
        }, {
            config: {
                hasLoading: true
            }
        });
    },
    // 修改登录密码
    setChangePwd(params) {
        return $axios.post(`/mall/v1/user/resetPwd`, {
            ...params
        }, {
            config: {
                hasLoading: true
            }
        });
    },
    // 修改用户信息
    setUserInfo(params) {
        return $axios.post(`/mall/v1/user/updateUserInfo`, {
            ...params
        }, {
            config: {
                hasLoading: true,
                dataType:2
            }
        });
    },
    // 取消新获得优惠券标识
    setCancelRemindCoupon(params) {
        return $axios.post(`/mall/v1/moudle/coupon/v1/notRemind/newCoupon`, {
            ...params
        })
    },
    // 取消新获得钱包标识
    setCancelRemindWallet(params) {
        return $axios.post(`/mall/v1/wallet/v1/notRemind/newWallet`, {
            ...params
        })
    },
});