/**
 * @description 会员
*/
export default $axios => ({
    // 获取会员信息
    getMemberInfo(params) {
        return $axios.get(`/mall/v1/member/findUserMemberLevel`, {
            params
        });
    },
    // 获取用户当前会员等级对应的权益列表
    getMemberLevelList(params) {
        return $axios.get(`/mall/v1/member/findMemberRightsByLevel`, {
            params
        });
    },
    // 获取会员成长明细
    getMemberPoint(params) {
        return $axios.get(`/mall/v1/member/findUserMemberDetail`, {
            params
        });
    },
    // 获取生日权益等级
    getMemberBirthdayLevel(params) {
        return $axios.get(`/mall/v1/member/findMemberBirthdayLevel`, {
            params
        });
    },
    // 获取生日权益优惠券
    getMemberBirthdayCoupon(params) {
        return $axios.get(`/mall/v1/member/findMemberBirthdayCoupon`, {
            params
        });
    },
    // 获取生日权益商品列表
    getMemberBirthdayProduct(params) {
        return $axios.get(`/mall/v1/member/findMemberBirthdayProduct`, {
            params
        });
    },
});