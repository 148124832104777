// 礼品卡
export default $axios => ({
    // 获取礼品卡详情
    getGiftCardDetail() {
        return $axios.get('/mall/v1/gift/card/findGiftCardDetail')
    },
    // 获取当前币种得礼品卡金额
    getGiftCardAmount() {
        return $axios.get('/mall/v1/gift/card/findThisCurrencyAmt')
    },
    // 获取礼品卡使用列表
    getGiftCardUse(params) {
        return $axios.get('/mall/v1/gift/card/queryGiftCardDetailList', {
            params,
        })
    },
    // 礼品卡兑换
    setGiftCardExchange(params, hasLoading = true) {
        return $axios.post(`/mall/v1/gift/card/exchangeGiftCard`, {
            ...params
        }, {
            config: {
                hasLoading,
            }
        })
    },
  });