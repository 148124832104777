export const BizBrand = () => import('../../components/BizBrand.vue' /* webpackChunkName: "components/biz-brand" */).then(c => wrapFunctional(c.default || c))
export const BizCategorySeo = () => import('../../components/BizCategorySeo.vue' /* webpackChunkName: "components/biz-category-seo" */).then(c => wrapFunctional(c.default || c))
export const BizEnsure = () => import('../../components/BizEnsure.vue' /* webpackChunkName: "components/biz-ensure" */).then(c => wrapFunctional(c.default || c))
export const BizRegister = () => import('../../components/BizRegister.vue' /* webpackChunkName: "components/biz-register" */).then(c => wrapFunctional(c.default || c))
export const CustomizeIframe = () => import('../../components/CustomizeIframe.vue' /* webpackChunkName: "components/customize-iframe" */).then(c => wrapFunctional(c.default || c))
export const FilterPopup = () => import('../../components/FilterPopup.vue' /* webpackChunkName: "components/filter-popup" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Head = () => import('../../components/Head.vue' /* webpackChunkName: "components/head" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Login = () => import('../../components/Login.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c))
export const Quick = () => import('../../components/Quick.vue' /* webpackChunkName: "components/quick" */).then(c => wrapFunctional(c.default || c))
export const Tab = () => import('../../components/Tab.vue' /* webpackChunkName: "components/tab" */).then(c => wrapFunctional(c.default || c))
export const YfnBag = () => import('../../components/YfnBag.vue' /* webpackChunkName: "components/yfn-bag" */).then(c => wrapFunctional(c.default || c))
export const YfnButton = () => import('../../components/YfnButton.vue' /* webpackChunkName: "components/yfn-button" */).then(c => wrapFunctional(c.default || c))
export const YfnCell = () => import('../../components/YfnCell.vue' /* webpackChunkName: "components/yfn-cell" */).then(c => wrapFunctional(c.default || c))
export const YfnCheckbox = () => import('../../components/YfnCheckbox.vue' /* webpackChunkName: "components/yfn-checkbox" */).then(c => wrapFunctional(c.default || c))
export const YfnCollapse = () => import('../../components/YfnCollapse.vue' /* webpackChunkName: "components/yfn-collapse" */).then(c => wrapFunctional(c.default || c))
export const YfnCountDown = () => import('../../components/YfnCountDown.vue' /* webpackChunkName: "components/yfn-count-down" */).then(c => wrapFunctional(c.default || c))
export const YfnCountScroll = () => import('../../components/YfnCountScroll.vue' /* webpackChunkName: "components/yfn-count-scroll" */).then(c => wrapFunctional(c.default || c))
export const YfnCountTo = () => import('../../components/YfnCountTo.vue' /* webpackChunkName: "components/yfn-count-to" */).then(c => wrapFunctional(c.default || c))
export const YfnField = () => import('../../components/YfnField.vue' /* webpackChunkName: "components/yfn-field" */).then(c => wrapFunctional(c.default || c))
export const YfnFooter = () => import('../../components/YfnFooter.vue' /* webpackChunkName: "components/yfn-footer" */).then(c => wrapFunctional(c.default || c))
export const YfnIcon = () => import('../../components/YfnIcon.vue' /* webpackChunkName: "components/yfn-icon" */).then(c => wrapFunctional(c.default || c))
export const YfnImage = () => import('../../components/YfnImage.vue' /* webpackChunkName: "components/yfn-image" */).then(c => wrapFunctional(c.default || c))
export const YfnList = () => import('../../components/YfnList.vue' /* webpackChunkName: "components/yfn-list" */).then(c => wrapFunctional(c.default || c))
export const YfnPagination = () => import('../../components/YfnPagination.vue' /* webpackChunkName: "components/yfn-pagination" */).then(c => wrapFunctional(c.default || c))
export const YfnPopup = () => import('../../components/YfnPopup.vue' /* webpackChunkName: "components/yfn-popup" */).then(c => wrapFunctional(c.default || c))
export const YfnProduct = () => import('../../components/YfnProduct.vue' /* webpackChunkName: "components/yfn-product" */).then(c => wrapFunctional(c.default || c))
export const YfnRadio = () => import('../../components/YfnRadio.vue' /* webpackChunkName: "components/yfn-radio" */).then(c => wrapFunctional(c.default || c))
export const YfnRate = () => import('../../components/YfnRate.vue' /* webpackChunkName: "components/yfn-rate" */).then(c => wrapFunctional(c.default || c))
export const YfnRegion = () => import('../../components/YfnRegion.vue' /* webpackChunkName: "components/yfn-region" */).then(c => wrapFunctional(c.default || c))
export const YfnShare = () => import('../../components/YfnShare.vue' /* webpackChunkName: "components/yfn-share" */).then(c => wrapFunctional(c.default || c))
export const YfnSku = () => import('../../components/YfnSku.vue' /* webpackChunkName: "components/yfn-sku" */).then(c => wrapFunctional(c.default || c))
export const YfnSkuBag = () => import('../../components/YfnSkuBag.vue' /* webpackChunkName: "components/yfn-sku-bag" */).then(c => wrapFunctional(c.default || c))
export const YfnSticky = () => import('../../components/YfnSticky.vue' /* webpackChunkName: "components/yfn-sticky" */).then(c => wrapFunctional(c.default || c))
export const YfnSwiper = () => import('../../components/YfnSwiper.vue' /* webpackChunkName: "components/yfn-swiper" */).then(c => wrapFunctional(c.default || c))
export const YfnTab = () => import('../../components/YfnTab.vue' /* webpackChunkName: "components/yfn-tab" */).then(c => wrapFunctional(c.default || c))
export const YfnTag = () => import('../../components/YfnTag.vue' /* webpackChunkName: "components/yfn-tag" */).then(c => wrapFunctional(c.default || c))
export const YfnUploader = () => import('../../components/YfnUploader.vue' /* webpackChunkName: "components/yfn-uploader" */).then(c => wrapFunctional(c.default || c))
export const YfnVideo = () => import('../../components/YfnVideo.vue' /* webpackChunkName: "components/yfn-video" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
