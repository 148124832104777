// 注册/登录
export default $axios => ({
    // 注册接口
    registerAjax(params, hasLoading) {
        return $axios.post(`/mall/v1/user/register`, {
            ...params
        }, {
            config: {
                hasLoading,
            }
        });
    },
    // 判断是否注册
    isRegisterAjax(params, hasLoading) {
        return $axios.post(`/mall/v1/module/user/v1/check/register`, {
            ...params
        }, {
            config: {
                hasLoading,
            }
        });
    },
    // 登录接口
    signAjax(params) {
        return $axios.post('/mall/v1/user/token/createTokenByLoginPwd', {
            ...params
        })
    },
    // 谷歌登录接口
    googleLogin(params) {
        return $axios.post('/mall/v1/third/googleLogin', {
            ...params
        },{
            config: {
                dataType: 2,
            }
        })
    },
    // 脸书登录接口
    facebookLogin(params) {
        return $axios.post('/mall/v1/third/facebookLogin', {
            ...params
        },{
            config: {
                dataType: 2,
            }
        })
    },
    // 脸书登录邮箱确认
    facebookConfirm(params) {
        return $axios.post('/mall/v1/third/facebookConfirm', {
            ...params
        },{
            config: {
                dataType: 2,
            }
        })
    },
    // 获取秘密
    pubKey(type = 1, hasLoading) {
        return $axios.get('/mall/v1/getMallPlatformPubKey',{
            config: {
                hasLoading,
                loadingType: type
            }
        })
    },
    // 发送重置密码邮件
    setResetLinkPwd(params, hasLoading) {
        return $axios.post('/mall/v1/user/resetPwdLink', {
            ...params
        }, {
            config: {
                hasLoading,
            }
        })
    },
    // 修改密码
    setResetPwd(params, hasLoading) {
        return $axios.post('/mall/v1/user/resetPwd', {
            ...params
        }, {
            config: {
                hasLoading,
            }
        })
    },
    // 重置密码前校验
    setResetCheckPwd(params, hasLoading) {
        return $axios.post('/mall/v1/user/verificationResetPwd', {
            ...params
        }, {
            config: {
                hasLoading,
            }
        })
    },
    // 获取注册认证码
    getRegisterCode(params) {
        return $axios.post('/mall/v1/certify/sendAuthCode', {
            ...params
        })
    },
    // 验证注册认证码
    checkRegisterCode(params, hasLoading) {
        return $axios.post('/mall/v1/certify/checkCode', {
            ...params
        }, {
            config: {
                hasLoading,
            }
        })
    },
  });