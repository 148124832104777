export default $axios => ({
    // 宣传栏配置接口
    getBillboard() {
        return $axios.get(`/mall/module/homepage/v1/homepage/getTopBillboard`);
    },
    // 获取底导
    getBottomNav() {
        return $axios.get(`/mall/module/homepage/v1/homepage/getBottomNavigation`);
    },
    // 获取进站弹窗广告
    getPopAd(params) {
        return $axios.get(`/mall/v1/popup/findAppPopup`, {
            params
        });
    },
    // 珠宝回收价目表
    getRecyclePrice(params) {
        return $axios.get(`/mall/v1/recycle/searchRecycle`, {
            params
        });
    },
    // 设置spm数据
    setSpmData(params) {
        return $axios.post(`/mall/v1/trace/spmTrace`,params, {
            config: {
                dataType: 2
            }
        })
    },
    // 设置ubr数据
    setUbrData(params) {
        return $axios.post(`/mall/v1/trace/ubrTrace`, params, {
            config: {
                dataType: 2
            }
        })
    },
    // fbq埋点数据
    sendFb(params) {
        return $axios.get(`/mall/v1/facebook/side/send`, {
            params
        });
    },
    // 社区分享日志
    communityShare(params) {
        return $axios.post(`/mall/v1/module/community/v1/posts/add`, params, {
            config: {
                hasLoading: true,
            },
        })
    },
});