export default $axios => ({
    // 获取网红申请状态
    getApplyStatus() {
        return $axios.get(`/mall/v1/module/influencer/v1/influencer/judgeInfluencer`);
    },
    // 获取网红信息
    getUserInfo() {
        return $axios.get(`/mall/v1/module/influencer/v1/influencer/getInfluencer`);
    },
    // 获取网红信用卡信息
    getCardInfo() {
        return $axios.get(`/mall/v1/module/influencer/v1/influencer/listInfluencerAccount`);
    },
    // 获取网红营收
    getEarning() {
        return $axios.get(`/mall/v1/module/influencer/v1/influencer/countCommission`);
    },
    // 获取网红结算订单
    getSettle(params) {
        return $axios.get(`/mall/v1/module/influencer/v1/influencer/listInfluencerSettlement`, {
            params,
        });
    },
    // 获取网红推广码、礼品码
    getUserCoupon(params) {
        return $axios.get(`/mall/v1/module/influencer/v1/influencer/listInfluencerCoupon`, {
            params,
        });
    },
    // 获取网红销售订单
    getOrderSale(params) {
        return $axios.get(`/mall/v1/module/influencer/v1/influencer/listCommerceOrder`, {
            params,
            config: {
                hasLoading: true
            }
        });
    },
    // 获取网红礼盒订单
    getOrderGift(params) {
        return $axios.get(`/mall/v1/module/influencer/v1/influencer/listGiftCodeOrder`, {
            params,
            config: {
                hasLoading: true
            }
        });
    },
    // 获取网红注册获取验证码
    getCertifyCode(params) {
        return $axios.get(`/mall/v1/user/getCertifyCode`, {
            params,
            config: {
                hasLoading: true
            }
        });
    },
    // 网红注册验证
    checkInfluencer(params) {
        return $axios.post(`/mall/v1/module/influencer/v1/influencer/checkInfluencer`, {
            ...params
        }, {
            config: {
                hasLoading: true,
            }
        })
    },
    // 修改网红信息
    setEditLink(params) {
        return $axios.post(`/mall/v1/module/influencer/v1/influencer/updateInfluencer`, {
            ...params
        }, {
            config: {
                hasLoading: true,
            }
        })
    },
    // 修改网红code
    setEditCode(params) {
        return $axios.post(`/mall/v1/module/influencer/v1/influencer/updateInfluencerCouponCode`, {
            ...params
        }, {
            config: {
                hasLoading: true,
            }
        })
    },
    // 网红更新卡号
    setUpdateCard(params) {
        return $axios.post(`/mall/v1/module/influencer/v1/influencer/updateInfluencerAccount`, {
            ...params
        }, {
            config: {
                hasLoading: true,
            }
        })
    },
    // 网红新增卡号
    setAddCard(params) {
        return $axios.post(`/mall/v1/module/influencer/v1/influencer/addInfluencerAccount`, {
            ...params
        }, {
            config: {
                hasLoading: true,
            }
        })
    },
    // 网红申请
    setRegister(params) {
        return $axios.post(`/mall/v1/module/influencer/v1/influencer/addInfluencer`, {
            ...params
        }, {
            config: {
                hasLoading: true,
            }
        })
    },
    // 删除网红申请信息
    deleteApply(params) {
        return $axios.get(`/mall/v1/module/influencer/v1/influencer/applyAgainInfluencer`, {
            params,
            config: {
                hasLoading: true
            }
        })
    },
  });