// 钱包
export default $axios => ({
    // 获取钱包列表
    getWalletList() {
        return $axios.get('/mall/v1/wallet/findUserWalletCountAmt',{
            config: {
                hasLoading: true,
            }
        })
    },
    // 获取钱包使用情况
    getWalletUseList() {
        return $axios.get('/mall/v1/wallet/findUserWalletDetails',{
            config: {
                hasLoading: true,
            }
        })
    },
    // 获取钱包余额
    getWalletBalanceList() {
        return $axios.get('/mall/v1/wallet/findUserThisWallet',{
            config: {
                hasLoading: true,
            }
        })
    },
  });