/**
 * @description 模板配置
*/
export default $axios => ({
    // 获取模板配置
    getTemplate(params) {
        return $axios.get(`/mall/v1/customPage/v1/customPage/info`, {
            params: {
                ...params
            },
            config: {
                hasLoading: true
            }
        });
    },
    // 获取商品列表数据
    getProductList(params) {
        return $axios.get(`/mall/v1/customPage/searchCustomPageProduct`, {
            params: {
                ...params
            },
        });
    },
    // 获取推荐商品列表数据
    getProductRecList(params) {
        return $axios.get(`/mall/v1/customPage/v1/page/customPage`, {
            params: {
                ...params
            },
        });
    }
});