/**
 * @description 购物车
*/
export default $axios => ({
    // 获取购物车列表
    getBag(params, hasLoading = true) {
        return $axios.post(`/mall/v3/cart/shopCartList`, params, {
            config: {
                hasLoading,
            }
        });
    },
    // 获取购物车数量
    getUserBagQuantity() {
        return $axios.get(`/mall/v1/cart/userCartCount`)
    },
    // 获取新人购物车商品列表
    getNewList(params) {
        return $axios.get(`/mall/v2/cart/searchShopCartNewcomer`, params)
    },
    // 修改购物车
    setUpdateBag(params) {
        return $axios.post('/mall/v1/cart/updateCart', params, {
            config: {
                // hasLoading: true,
            },
        })
    },
    // 购物车商品删除
    deleteBag(params) {
        return $axios.post('/mall/v1/cart/deleteCart', params, {
            config: {
                hasLoading: true,
            },
        })
    },
    // 更换购物车赠品
    changeGiftBag(params) {
        return $axios.post('/mall/v2/cart/cartSaveFreeGift', params, {
            config: {
                hasLoading: true,
            },
        })
    },
    // 购物车vip删除
    deleteVip(params) {
        return $axios.post('/mall/v1/cart/updateCartDelVipService', params, {
            config: {
                hasLoading: true,
            },
        })
    },
    // 购物车库存校验
    setBagStock(hasLoading) {
        return $axios.get('/mall/v1/cart/checkShopCartStock', {
            config: {
                hasLoading
            }
        })
    },
    // 购物车新人专享商品替换
    changeNewProduct(params) {
        return $axios.post('/mall/v2/cart/updateOnSelectedNewcomer', params, {
            config: {
                hasLoading: true,
            },
        })
    },
    // 购物车匹配最优优惠券/验证优惠券/移除优惠券
    checkCoupon(params) {
        return $axios.post('/mall/v1/coupon/checkCartCoupons', params)
    },
    // 购物车兑换优惠券
    exchangeCoupon(params) {
        return $axios.post('/mall/v1/coupon/cartApplyCoupons', params)
    },
});