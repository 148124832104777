/**
 * @description 活动
*/
export default $axios => ({
    // 获取活动信息-通用
    getActivityInfo(params, config) {
        return $axios.get(`/mall/activity/pubActivityDetail`, {
            params,
            headers: config.headers || {},
            config: {
                hasLoading: true
            }
        });
    },
    // 获取活动列表
    getActivityList(params, config = {}) {
        return $axios.get(`/mall/activity/searchOnlineActivity`, {
            params,
            headers: config.headers || {},
        });
    },
    // 获取满折活动信息
    getFullFoldInfo(params, config) {
        return $axios.get(`/mall/v1/stock/buyMore/getBuyMoreDetails`, {
            params,
            headers: config.headers || {},
            config: {
                hasLoading: true
            }
        });
    },
    // 获取满折列表
    getFullFoldList(params, config) {
        return $axios.get(`/mall/v1/stock/buyMore/searchGoodsList`, {
            params,
            headers: config.headers || {},
        });
    },
    // 获取满减活动信息
    getFullReduceInfo(params, config) {
        return $axios.get(`/mall/v1/reduced/getReducedDetails`, {
            params,
            headers: config.headers || {},
            config: {
                hasLoading: true
            }
        });
    },
    // 获取满减列表
    getFullReduceList(params, config) {
        return $axios.get(`/mall/v1/reduced/searchReducedGoods`, {
            params,
            headers: config.headers || {},
        });
    },
    // 获取满赠活动信息
    getFreeGiftInfo(params, config) {
        return $axios.get(`/mall/v1/stock/activity/getActivityDetails`, {
            params,
            headers: config.headers || {},
            config: {
                hasLoading: true
            }
        });
    },
    // 获取满减列表
    getFreeGiftList(params, config) {
        return $axios.get(`/mall/v1/stock/activity/searchGoods`, {
            params,
            headers: config.headers || {},
        });
    },
    // 获取每月上新活动信息
    getMonthlyNewInfo(params, config) {
        return $axios.get(`/mall/v1/charts/newly/searchChartsNewly`, {
            params,
            headers: config.headers || {},
            config: {
                hasLoading: true
            }
        });
    },
    // 获取每月上新列表
    getMonthlyNewList(params, config) {
        return $axios.get(`/mall/v1/charts/newly/searchNewlyGoods`, {
            params,
            headers: config.headers || {},
        });
    },
    // 获取闪购活动信息
    getFlashSaleInfo(params, config) {
        return $axios.get(`/mall/v1/flash/getFlashActivityOnlyThree`, {
            params,
            headers: config.headers || {},
            config: {
                hasLoading: true
            }
        });
    },
    // 获取闪购列表
    getFlashSaleList(params, config) {
        return $axios.get(`/mall/v1/flash/getFlashGoodsList`, {
            params,
            headers: config.headers || {},
        });
    },
    // 获取畅销榜活动信息
    getBestSellerInfo(params, config) {
        return $axios.get(`/mall/v1/charts/sales/findChartsSales`, {
            params,
            headers: config.headers || {},
            config: {
                hasLoading: true
            }
        });
    },
    // 获取畅销榜列表
    getBestSellerList(params, config) {
        return $axios.get(`/mall/v1/charts/sales/searchSalesGoods`, {
            params,
            headers: config.headers || {},
        });
    },
    // 获取新人专享活动信息
    getNewComerInfo(params, config) {
        return $axios.get(`/mall/v1/newcomer/searchNewcomerActivity`, {
            params,
            headers: config.headers || {},
            config: {
                hasLoading: true
            }
        });
    },
    // 获取新人专享列表
    getNewComerList(params, config) {
        return $axios.get(`/mall/v1/newcomer/searchNewcomerActivityGoods`, {
            params,
            headers: config.headers || {},
        });
    },
});