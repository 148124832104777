/**
 * @description 商品评论
*/
export default $axios => ({
    // 获取集合页评论
    getCenterReview(params, hasLoading = false) {
        return $axios.get(`/mall/v1/spu/reviewsPage`, {
            params: {
                ...params
            },
            config: {
                hasLoading
            }
        });
    },
    // 获取评论
    getReview(params, options = {}) {
        return $axios.get(`/mall/v1/spu/searchSpuComment`, {
            params: {
                ...params
            },
            config: {
                hasLoading: options.hasLoading
            }
        });
    },
    // 获取待评价订单
    getOrderReviewNo(params) {
        return $axios.get(`/mall/v1/module/order/v1/needComment/list`, {
            ...params
        });
    },
    // 获取已评价订单
    getOrderReviewed(params) {
        return $axios.get(`/mall/v1/module/comment/v1/userComment/page`, {
            params,
            config: {
                hasLoading: true
            }
        });
    },
    // 获取订单评价信息
    getOrderReviewInfo(params) {
        return $axios.get(`/mall/v1/module/order/v1/getComment/info`, {
            params: {
                ...params
            },
            config: {
                hasLoading: true
            }
        });
    },
    // 获取订单评价分享信息
    getOrderReviewShareInfo(params) {
        return $axios.get(`/mall/v1/module/comment/v1/share/commentInfo`, {
            params: {
                ...params
            }
        });
    },
    // 获取订单评价分享top评价商品
    getOrderReviewShareProduct(params) {
        return $axios.get(`/mall/v1/module/comment/v1/share/topCommentSpuList`, {
            params: {
                ...params
            },
            config: {
                hasLoading: true
            }
        });
    },
    // 获取订单评价配置信息
    getOrderReviewConfig(params) {
        return $axios.get(`/mall/v1/comment/v1/get/config`, {
            params
        });
    },
    // 获取订单评价状态
    getOrderReviewStatus(params) {
        return $axios.post(`/mall/v1/module/order/v1/check/commented`, {
            ...params
        }, {
            config: {
                hasLoading: true
            }
        });
    },
    // 评论点赞
    setAgree(params) {
        return $axios.post(`/mall/v1/liked/likedSpuComment`, {
            ...params
        }, {
            config: {
                hasLoading: true
            }
        });
    },
    // 提交订单评价
    setOrderComment(params) {
        return $axios.post(`/mall/v1/module/order/v1/create/comment`, {
            ...params
        }, {
            config: {
                hasLoading: true
            }
        });
    },
    // 提交订单评价-邮件
    setOrderCommentEmail(params) {
        return $axios.post(`/mall/v1/liked/likedSpuComment`, {
            ...params
        }, {
            config: {
                hasLoading: true
            }
        });
    },
});